import { gsap } from 'gsap';
import { MIDDLE_EAST } from '@/constants/Themes';

let isCoveringPage = false;

export default function useAnimateMobileMenu(theme?: string) {
  let canvas: HTMLCanvasElement;
  let vw: number;
  let vh: number;
  let canvas2DContext: CanvasRenderingContext2D | null;
  let ripple: any = {};
  const rippleBackgroundColorRGB = theme === MIDDLE_EAST ? '0,37,60' : '0,0,0';
  const { getXValue } = useAnimationHelper();

  onMounted((): void => {
    canvas = document.querySelector('.mobile-menu-canvas') as HTMLCanvasElement;
  });

  const resetAnimation = (): void => {
    isCoveringPage = false;
    ripple = {};

    if (canvas === null) {
      return;
    }

    canvas.style.display = 'none';
  };

  const drawRipple = (): void => {
    if (canvas2DContext === null) {
      return;
    }

    const piTwo = Math.PI * 2;

    canvas2DContext.clearRect(0, 0, vw, vh);
    canvas2DContext.globalCompositeOperation = 'source-over';

    if (!isCoveringPage) {
      canvas2DContext.beginPath();
      canvas2DContext.rect(0, 0, vw, vh);

      canvas2DContext.fillStyle = rippleBackgroundColorRGB;
      canvas2DContext.fill();

      canvas2DContext.globalCompositeOperation = 'destination-out';
    }

    canvas2DContext.beginPath();
    canvas2DContext.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
    canvas2DContext.fillStyle = `rgba(${rippleBackgroundColorRGB},${ripple.alpha})`;
    canvas2DContext.fill();
  };

  const createRipple = (event: PointerEvent): void => {
    isCoveringPage = !isCoveringPage;

    const x = event.clientX;
    const y = event.clientY;

    const dx = x < vw / 2 ? vw - x : x;
    const dy = y < vh / 2 ? vh - y : y;

    const radius = Math.sqrt(dx * dx + dy * dy);
    const alpha = isCoveringPage ? 0 : 1;

    ripple = {
      alpha,
      radius: 0,
      x,
      y,
    };

    const mobileMenu = document.querySelector('.mobile-menu');

    gsap.to(ripple, {
      duration: isCoveringPage ? 1 : 1.2,
      alpha: 1,
      radius,
      ease: 'power4.out',
      onUpdate: drawRipple,
      callbackScope: ripple,
    });

    const tl = gsap.timeline();

    if (isCoveringPage) {
      tl.set(mobileMenu, {
        duration: 0,
        opacity: 1,
        display: 'block',
      });
      tl.fromTo(
        document.querySelector('.mobile-menu__inner'),
        {
          opacity: 0,
          x: `${getXValue(-500)}`,
        },
        {
          duration: 0.5,
          opacity: 1,
          x: 0,
        },
      );
      tl.fromTo(
        document.querySelector('.social-media--with-ellipse'),
        {
          opacity: 0,
          y: 100,
          x: '-50%',
        },
        {
          duration: 0.3,
          opacity: 1,
          y: 0,
          x: '-50%',
        },
        '<',
      );
    } else {
      tl.set(mobileMenu, {
        display: 'block',
      });
      tl.fromTo(
        mobileMenu,
        {
          opacity: 1,
        },
        {
          duration: 0.1,
          opacity: 0,
        },
      );
    }
  };

  const animateMobileMenu = (event: PointerEvent): void => {
    if (canvas === null) {
      return;
    }

    canvas.style.display = 'block';

    vw = window.innerWidth;
    vh = window.innerHeight;

    canvas.width = vw;
    canvas.height = vh;

    canvas2DContext = canvas.getContext('2d');

    createRipple(event);
  };

  return {
    animateMobileMenu,
    resetAnimation,
  };
}
