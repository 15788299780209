<template>
  <div class="country-and-language-switcher">
    <BaseDropdown
      :open-above="openAbove"
      :open-centered="openCentered"
      :open-start="openStart"
      trigger-data-test="country-and-language-switcher-trigger"
    >
      <template #dropdown-trigger>
        <NuxtIcon name="globe" class="country-and-language-switcher__icon" />
        <span class="country-and-language-switcher__current-language">
          {{ $t(`languages.${i18n.locale.value}`) }}
        </span>
      </template>
      <template #dropdown-content>
        <CountrySwitcher v-if="config.public.theme === MIDDLE_EAST" />

        <div v-if="isFeatureEnabled('featureShowLanguageSwitcher') && locales.length > 1" class="country-and-language-switcher__language-inner">
          <div
            v-if="config.public.theme === MIDDLE_EAST"
            class="country-and-language-switcher__heading heading heading--small"
          >
            {{ $t('country_and_language_switcher.language_heading') }}
          </div>
          <LanguageSwitcherItem
            v-for="locale in locales"
            :key="locale.code"
            :active="locale.code === i18n.locale.value"
            :href="url(locale.code)"
          >
            <div class="country-and-language-switcher__languages" :lang="locale.code">
              <span class="country-and-language-switcher__languages-translated">
                {{ $t(`languages.${locale.code}`) }}
              </span>
              <span v-if="config.public.theme === EUROPE" class="country-and-language-switcher__languages-origin">
                {{ $t(`languages-origin.${locale.code}`) }}
              </span>
            </div>
          </LanguageSwitcherItem>
        </div>
      </template>
    </BaseDropdown>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig, useRoute } from 'nuxt/app';
import { computed } from 'vue';
import { useI18n, useSwitchLocalePath } from '#i18n';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import { MIDDLE_EAST, EUROPE } from '@/constants/Themes';
import { LOCALES } from '@/constants/locales';
import CountrySwitcher from '@/components/CountrySwitcher.vue';
import LanguageSwitcherItem from '@/components/LanguageSwitcherItem.vue';

const config = useRuntimeConfig();
const i18n = useI18n();
const switchLocalePath = useSwitchLocalePath();
const route = useRoute();
const { isFeatureEnabled } = useFeatureFlags(config);

const localesEurope = [{
  code: 'nl',
  domain: config.public.domains?.europe.nl,
},
{
  code: 'es',
  domain: config.public.domains?.europe.es,
},
{
  code: 'it',
  domain: config.public.domains?.europe.it,
}];

defineProps({
  openAbove: {
    type: Boolean,
    default: false,
  },
  openCentered: {
    type: Boolean,
    default: false,
  },
  openStart: {
    type: Boolean,
    default: false,
  },
});

const url = (locale: string): string => {
  if (config.public.theme === 'middle-east') {
    if (route.name?.startsWith('content')) {
      return '/';
    }

    return switchLocalePath(locale);
  }
  return localesEurope.find((localeEurope) => localeEurope.code === locale)?.domain || '';
};

const locales = computed(() => (config.public.theme === 'middle-east'
  ? i18n.locales.value.filter((locale: any) => {
    const themeLocales = LOCALES[config.public.theme];
    return themeLocales.includes(locale.code);
  })
  : localesEurope));

</script>

<style lang="scss" scoped>
.country-and-language-switcher {
  font-family: $title-font;
  font-size: to-rem(18);
  color: $white;
  display: flex;
  gap: 8px;
  align-items: center;

  &__heading {
    margin-block-end: 8px;
  }

  &__language-inner {
    padding-block-end: 24px;
  }

  &__current-language {
    text-transform: uppercase;
  }

  &__languages {
    display: flex;
    flex-direction: column;
  }

  &__languages-origin {
    color: $primary-color-100;
    font-size: to-rem(14);
  }

  &__icon {
    font-size: 24px;
  }

  :deep(.dropdown__items--start) {
    transform: none;

    @include respond-min($mobile-menu-breakpoint) {
      @include translate-element-width-left(102px);
    }
  }
}

.layout--europe,
.layout--campaign {
  .dropdown {
    inline-size: 100%;
  }

  .country-and-language-switcher {
    &__language-inner {
      padding-block-end: 0;
    }

    &__current-language {
      text-transform: none;
    }

    &__languages {
      transition: transform 0.3s ease-in-out;
    }
  }
}
</style>
