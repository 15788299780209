<template>
  <div
    v-if="list || title"
    class="menu"
  >
    <div
      class="menu__title"
      :class="{
        'heading--h4': config.public.theme === EUROPE,
      }"
    >
      {{ title ? title : list.title[i18n.locale.value] }}
    </div>
    <ul
      v-if="list"
      class="menu__list"
    >
      <li
        v-for="(item, index) in list.children"
        :key="index"
        class="menu__list-item"
      >
        <BaseLink :url="item.url">
          {{ item.title }}
        </BaseLink>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script setup lang="ts" scoped>
import { useRuntimeConfig } from 'nuxt/app';
import { useI18n } from '#i18n';
import BaseLink from '@/components/base/BaseLink.vue';
import { EUROPE } from '@/constants/Themes';

defineProps({
  list: {
    type: Object,
    default: () => {},
  },
  title: {
    type: String,
    default: '',
  },
});

const i18n = useI18n();
const config = useRuntimeConfig();
</script>

<style lang="scss">
.menu {
  &__title {
    margin: 0 0 16px;

    .layout--europe & {
      margin-block-end: 24px;

      @include respond-min($tablet) {
        margin-block-end: 36px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include respond-min($footer-breakpoint) {
      gap: 16px;
    }

    &-item {
      list-style: none;
    }
  }
}
</style>
