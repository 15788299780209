import { onMounted, ref } from 'vue';
import useAnimateMobileMenu from '@/composables/useAnimateMobileMenu';

const isMobileMenuActive = ref(false);

export default function useMobileMenu(theme?: string) {
  const { animateMobileMenu, resetAnimation } = useAnimateMobileMenu(theme);

  const toggleMobileMenu = (event: PointerEvent): void => {
    isMobileMenuActive.value = !isMobileMenuActive.value;
    document.body.classList.toggle('locked', isMobileMenuActive.value);
    animateMobileMenu(event);
  };

  const closeMobileMenu = (): void => {
    isMobileMenuActive.value = false;
    document.body.classList.remove('locked');
    resetAnimation();
  };

  const onWindowResize = () => {
    closeMobileMenu();
  };

  onMounted(() => {
    window.addEventListener('resize', onWindowResize);
  });

  return {
    toggleMobileMenu,
    closeMobileMenu,
    isMobileMenuActive,
  };
}
