<template>
  <nav class="secondary-navigation">
    <CountryAndLanguageSwitcher
      :data-key="i18n.locale.value"
      open-start
    />
  </nav>
</template>

<script setup lang="ts">
import { useI18n } from '#i18n';
import CountryAndLanguageSwitcher from '@/components/CountryAndLanguageSwitcher.vue';

const i18n = useI18n();
</script>

<style scoped lang="scss">
.secondary-navigation {
  display: none;
  font-size: to-rem(18);
  line-height: 22px;
  text-transform: $main-navigation-text-transform;

  @include respond-min($mobile-menu-breakpoint) {
    display: flex;
    align-items: center;
    gap: 48px;
    margin-inline-end: 40px;
  }

  :deep(.country-and-language-switcher) {
    display: none;

    @include respond-min($mobile-menu-breakpoint) {
      display: block;
    }
  }

  &__link-icon {
    font-size: 10px;
    margin-block-end: -1px;
  }
}

.secondary-navigation__link--round-border {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 2px solid $white;
  border-radius: 30px;
  padding: 17px 24px;

  @include respond-min($tablet) {
    padding: 8px 24px;
  }
}
</style>
