<template>
  <footer data-test="footer">
    <div v-if="config.public.theme === EUROPE" class="footer__curve-top curve-up">
      <CurveUp />
    </div>

    <div class="footer">
      <div class="footer__content">
        <div class="footer__main">
          <MenuList v-if="navigation.footer_group_1" :list="navigation.footer_group_1" class="footer__menu" />
          <MenuList
            v-if="snippets?.footerMenuLinksTitle && snippets?.footerMenuLinksChildren"
            :title="snippets.footerMenuLinksTitle"
            :list="snippets.footerMenuLinksChildren"
            class="footer__menu"
          />
          <MenuList v-if="navigation.footer_group_2" :list="navigation.footer_group_2" class="footer__menu" />
          <MenuList :title="$t('general.change_language')" class="footer__menu footer__menu--mobile-only">
            <CountryAndLanguageSwitcher :open-above="true" />
          </MenuList>
        </div>

        <div class="footer__bottom">
          <div class="footer__bottom-content">
            <div v-if="navigation.footer_bottom" class="footer__bottom-links">
              <div
                v-for="(item, index) in navigation.footer_bottom.children"
                :key="item.title"
                class="footer__bottom-link"
              >
                <BaseLink :url="item.url">
                  {{ item.title }}
                </BaseLink>
                <NuxtIcon v-if="index % 2 === 0" name="half-dot" class="footer__bottom-links-separator" filled />
              </div>
            </div>
            <div v-if="snippets?.socialLinks" class="footer__bottom-socials">
              <span class="footer__bottom-socials-title">{{ $t('footer.social-media.title') }}</span>
              <SocialMedia :content="snippets.socialLinks.content.social_links" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useAsyncData, useRuntimeConfig } from 'nuxt/app';
import { computed } from 'vue';
import { useI18n } from '#i18n';
import CurveUp from '@/assets/patterns/curve-up.svg?component';
import CountryAndLanguageSwitcher from '@/components/CountryAndLanguageSwitcher.vue';
import MenuList from '@/components/MenuList.vue';
import SocialMedia from '@/components/SocialMedia.vue';
import useCms from '@/composables/useCms';
import { EUROPE } from '@/constants/Themes';
import { useNavigationStore } from '@/stores/navigation';

const store = useNavigationStore();
const i18n = useI18n();
const config = useRuntimeConfig();

const navigation = computed(() => store.footer(i18n.locale.value));
const { getSnippet } = useCms(i18n, config.public.apiUrl, config.public.theme);
const { data: snippets } = await useAsyncData('snippets', async () => {
  const footerMenuLinks = await getSnippet('footer_menu_links');

  const footerMenuLinksChildren = {
    children: footerMenuLinks?.content?.footer_links,
  };
  const footerMenuLinksTitle = footerMenuLinks?.content?.title;

  const socialLinks = await getSnippet('social_links');

  return { footerMenuLinksTitle, footerMenuLinksChildren, socialLinks };
});
</script>

<style lang="scss" scoped>
.footer {
  background-color: $primary-color-700;
  color: $white;
  padding: 100px 0 0;

  @include respond-min($footer-breakpoint) {
    padding: 80px 0 0;
  }

  &__main {
    padding: 0 30px;
    max-inline-size: $content-width;
    margin: 0 auto;
    min-block-size: 330px;

    @include respond-min($footer-breakpoint) {
      display: flex;
      gap: 20px;
    }
  }

  &__menu {
    margin-block-end: 64px;

    @include respond-min($footer-breakpoint) {
      flex: 1;
    }
  }

  &__bottom {
    position: relative;
    display: flex;
    min-block-size: 120px;
    margin-block-start: 75px;
    background-color: $primary-color-900;

    &::before {
      content: '';
      position: absolute;
      inset-block-start: -30px;
      inline-size: 100%;
      block-size: 30px;
      background-color: $primary-color-900;
      mask-image: url('/backgrounds/footer-bottom-mobile.svg');
      mask-size: 100%;

      @include respond-min($footer-breakpoint) {
        mask-image: url('/backgrounds/footer-bottom.svg');
      }
    }

    @include respond-min($footer-breakpoint) {
      min-block-size: 95px;
    }

    &-content {
      max-inline-size: 1120px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      @include respond-min($footer-breakpoint) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 30px;
      }
    }

    &-links {
      order: 1;
      display: flex;
      align-items: flex-end;
      margin-block-end: 16px;
      font-size: to-rem(16);

      @include respond-min($footer-breakpoint) {
        order: 0;
      }

      &-separator {
        margin: 0 8px 3px 4px;

        &:lang(ar) {
          margin: 3px 4px 0 8px;
        }
      }
    }
  }
}

.layout--europe,
.layout--campaign {
  .footer {
    &__curve-top {
      position: absolute;
      inset-block-start: -100px;
      z-index: map-get($z-index, 'positive');

      > svg {
        fill: $primary-color-700;
      }
    }
  }
}

.layout--campaign {
  .footer {
    &__curve-top {
      z-index: map-get($z-index, 'footer');

      @include respond-min($tablet) {
        display: none;
      }
    }

    @include respond-max($tablet) {
      padding: 0;
    }
  }
}

.layout--europe {
  .footer {
    padding: 0;
    margin-block-start: 40px;

    @include respond-min($desktop) {
      margin-block-start: 80px;
    }

    &__curve-top {
      @include respond-min($desktop) {
        inset-block-start: -200px;
      }
    }

    &__menu {
      @include respond-min($footer-breakpoint) {
        inline-size: 170px;

        &:not(:first-child) {
          padding-inline-start: 30px;
          border-inline-start: 1px solid rgba($white, 0.12);
        }
      }
    }

    &__bottom {
      min-block-size: 0;

      &::before {
        mask-image: url('/patterns/curve-up.svg');
        inline-size: 120vw;
        margin-inline-start: -10vw;
        block-size: 100px;
        inset-block-start: -60px;
        overflow: hidden;

        @include respond-min($desktop) {
          block-size: 140px;
          inset-block-start: -80px;
        }
      }
    }

    &__bottom-content {
      z-index: map-get($z-index, 'positive');
    }

    &__bottom-link {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__bottom-links-separator {
      @include respond-max($tablet) {
        font-size: 8px;
      }
    }

    &__bottom-socials {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-block-end: 24px;

      :deep(.social-media) {
        margin-block-end: 0;
      }
    }

    &__bottom-socials-title {
      @include respond-max($tablet) {
        display: none;
      }
    }
  }

  .footer__menu--no-width {
    inline-size: auto;
  }

  .footer__menu--mobile-only {
    @include respond-min($tablet) {
      display: none;
    }
  }
}
</style>
