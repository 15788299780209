<template>
  <nav
    class="main-navigation"
    data-test="main-navigation"
  >
    <BaseDropdown
      v-if="i18n.locale.value !== ES"
      :horizontal="true"
      trigger-data-test="products-dropdown-trigger-desktop"
    >
      <template #dropdown-trigger>
        {{ $t('general.products') }}
      </template>
      <template #dropdown-content>
        <ProductDropdownItems />
      </template>
    </BaseDropdown>
    <BaseLink
      v-for="navigationItem in navigationItems"
      :key="navigationItem.url"
      :url="navigationItem.url"
      class="main-navigation__item"
    >
      {{ navigationItem.title }}
    </BaseLink>
  </nav>
</template>

<script setup lang="ts">
import ProductDropdownItems from '@/components/ProductDropdownItems.vue';
import BaseDropdown from '@/components/base/BaseDropdown.vue';
import BaseLink from '@/components/base/BaseLink.vue';
import { ES } from '@/constants/locales';
import { useNavigationStore } from '@/stores/navigation';

const store = useNavigationStore();
const i18n = useI18n();

const navigationItems = computed(() => store.main(i18n.locale.value));
</script>

<style scoped lang="scss">
.main-navigation {
  display: none;
  text-transform: $main-navigation-text-transform;

  @include respond-min($mobile-menu-breakpoint) {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    font-size: to-rem(18);
    gap: 48px;
  }
}
</style>
