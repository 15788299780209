<template>
  <div
    ref="dropdown"
    class="dropdown"
    :class="{
      'dropdown--open': open,
      'dropdown--horizontal': horizontal,
    }"
  >
    <button type="button" :data-test="triggerDataTest" class="dropdown__trigger" @click="toggle">
      <slot name="dropdown-trigger" />
      <NuxtIcon name="chevron-down" class="dropdown__arrow" />
    </button>
    <div
      v-show="open"
      ref="items"
      class="dropdown__items"
      :class="{
        'dropdown__items--above': openAbove,
        'dropdown__items--start': openStart,
        'dropdown__items--centered': openCentered,
      }"
      @click="toggle"
      @keyup.enter="toggle"
    >
      <slot name="dropdown-content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEventListener } from '@vueuse/core';
import { gsap } from 'gsap';
import { useNuxtApp } from 'nuxt/app';
import {
  type PropType, type Ref, ref, watch,
} from 'vue';

const { $bus } = useNuxtApp();

defineProps({
  openAbove: {
    type: Boolean,
    default: false,
  },
  triggerDataTest: {
    type: String,
    default: '',
  },
  openCentered: {
    type: Boolean,
    default: false,
  },
  openStart: {
    type: Boolean,
    default: false,
  },
  horizontal: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const dropdown: Ref<HTMLElement | undefined> = ref();
const items = ref(null);
const open = ref(false);

$bus.$on('header-dropdown:close', (): void => {
  const dropdownHeaderOpen = document.querySelector('.header .dropdown--open');
  if (dropdownHeaderOpen === null) {
    return;
  }

  open.value = false;
});

const emit = defineEmits(['open']);

const toggle = () => {
  open.value = !open.value;
  emit('open', open.value);
};

const CloseonClickOutside = (event: MouseEvent) => {
  if (!open.value) {
    return;
  }

  const eventTarget = event.target as HTMLElement;

  if (!(dropdown.value === event.target || dropdown.value?.contains(eventTarget))) {
    toggle();
  }
};

const CloseWhenLostFocus = (event: KeyboardEvent) => {
  if (
    event.key === 'Tab'
    && open.value
    && !(
      dropdown.value === document.activeElement || dropdown.value?.contains(document.activeElement)
    )
  ) {
    toggle();
  }
};

if (process.client) {
  useEventListener(document, 'click', CloseonClickOutside);
  useEventListener(document, 'keyup', CloseWhenLostFocus);
}

const animateDropdown = (): void => {
  gsap.from(items.value, {
    scale: 0,
    opacity: 0,
    duration: 0.5,
    ease: 'back.out',
  });
};

watch(
  () => open.value,
  () => {
    if (open.value) {
      animateDropdown();
    }
  },
);
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  &__trigger {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    color: $white;
    outline: none;
    text-transform: $dropdown-text-transform;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__arrow {
    font-size: 12px;
    transition: transform 0.3s;
  }
}

.dropdown--open {
  .dropdown__arrow {
    transform: rotate(180deg);
  }
}

.dropdown__items {
  position: absolute;
  background-color: $dropdown-background;
  min-inline-size: 290px;
  padding: 0 24px;
  border-radius: $dropdown-border-radius;
  text-transform: $dropdown-text-transform;
  box-shadow: 0 8px 16px rgba($black, 0.15);
  z-index: map-get($z-index, 'dropdown');
  inset-block-start: 74px;
  transform-origin: center top;

  @include respond-max($mobile-menu-breakpoint) {
    inline-size: calc(100% + 32px);
    margin: 0 -16px;
  }

  @include respond-min($mobile-menu-breakpoint) {
    inset-block-start: 50px;

    .dropdown--horizontal & {
      inset-inline-start: -174px * 2 + 50px;

      .layout--europe &,
      .layout--campaign & {
        inset-inline-start: -50px;
      }
    }
  }

  &::before {
    content: '';
    background-color: $dropdown-background;
    mask-image: url('/backgrounds/dropdown-top.svg');
    mask-repeat: no-repeat;
    mask-position: center top;
    inline-size: 100%;
    block-size: 15px;
    position: absolute;
    inset-inline: 0;
    inset-block-start: -15px;

    [dir='rtl'] & {
      transform: scaleX(-1);
    }

    .layout--europe &,
    .layout--campaign & {
      display: none;
    }
  }

  &--centered {
    @include respond-min($mobile-menu-breakpoint) {
      @include exception-left;

      transform: translateX(-50%);
    }
  }

  &--above {
    inset-block: auto 50px;

    &::before {
      inset-block: -14px;
      inset-block-start: auto;
      transform: scaleY(-1);

      [dir='rtl'] & {
        transform: scaleX(-1) scaleY(-1);
      }
    }
  }
}

.dropdown__items--start {
  transform: translateX(-100%);

  [dir='rtl'] & {
    transform: translateX(100%);
  }
}

.layout--europe,
.layout--campaign {
  .dropdown__items {
    padding: 0;

    @include respond-min($mobile-menu-breakpoint) {
      padding: 0 26px;
    }
  }
}
</style>
