<template>
  <div
    class="product-dropdown-items"
    :class="[
      { 'product-dropdown-items--mobile-menu': isMobileMenuActive },
      `product-dropdown-items--${config.public.theme}`,
    ]"
    data-test="product-dropdown-items"
  >
    <BaseLink
      v-for="(category, index) in themeCategories"
      :key="index"
      :url="{ name: 'products', query: { category: category.slug } }"
      class="category"
      :class="isMobileMenuActive ? `category-mobile-menu category--${category.slug}` : `category--${category.slug}`"
      data-test="product-dropdown-item"
    >
      <NuxtPicture
        class="category__image-wrapper"
        :src="category.image"
        :img-attrs="{ class: 'category__image' }"
      />
      <div
        v-if="category.secondImage"
        class="category-mobile-menu__image-wrapper"
      >
        <NuxtImg
          :src="category.secondImage"
          class="category-mobile-menu__image"
        />
      </div>

      <div
        class="category__text"
        :class="{ 'category-mobile-menu__text': isMobileMenuActive }"
      >
        {{ $t(`product_filters.${category.slug}`) }}
      </div>
      <NuxtIcon
        v-if="config.public.theme === MIDDLE_EAST"
        name="product-category"
        class="category__type"
      />
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app';
import { computed } from 'vue';
import useMobileMenu from '@/composables/useMobileMenu';
import { MIDDLE_EAST } from '@/constants/Themes';
import BaseLink from '@/components/base/BaseLink.vue';
import productCategories from '@/constants/ProductCategories';

const { isMobileMenuActive } = useMobileMenu();
const config = useRuntimeConfig();
const i18n = useI18n();

// Get all the product categories for current theme and locale
const themeCategories = computed(() => productCategories[config.public.theme].filter(
  (cat) => cat.locales === undefined || cat.locales.includes(i18n.locale.value),
));
</script>

<style scoped lang="scss">
.product-dropdown-items {
  display: flex;
  margin: 0 -30px;

  &--mobile-menu {
    flex-direction: column;
    justify-content: flex-start;
    inline-size: 100%;
    position: relative;
    margin: 0;
    padding: 21px 0;
  }
}

.category {
  position: relative;

  @include respond-max($mobile-menu-breakpoint) {
    block-size: 110px;

    &:not(:first-child) {
      border-block-start: 1px solid $neutral-300;
    }
  }

  @include respond-min($mobile-menu-breakpoint) {
    padding: 60px 20px 70px;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    inline-size: 134px;
    block-size: 134px;

    @include respond-max($mobile-menu-breakpoint) {
      display: none;
    }
  }

  &__text {
    color: $dropdown-color;
    inline-size: 100%;
    transition: transform 0.3s ease-in-out;

    @include respond-min($mobile-menu-breakpoint) {
      @include exception-left;

      position: absolute;
      text-align: center;
      padding: 0 30px;
      inset-block-end: 30px;
      transform: translate(-50%, 0);
    }
  }

  &__type {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;

    :deep(svg) {
      inline-size: 38px;
      block-size: 28px;
    }

    @include respond-max($mobile-menu-breakpoint) {
      inline-size: 59px;
      block-size: 44px;
      inset-block-start: 18px;
      inset-inline-start: 21px;
      z-index: map-get($z-index, 'negative');
    }

    @include respond-min($mobile-menu-breakpoint) {
      @include exception-left;

      transition: opacity 0.5s ease-in-out;
      inset-block-end: -3px;
      inline-size: 100%;
      opacity: 0;
      transform: translateX(-50%);
    }
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    block-size: 100%;
    inline-size: 100%;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  &::after {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    content: '';
    inline-size: 100%;
    block-size: 30px;
    position: absolute;
    background-repeat: no-repeat;
    inset-block-start: -15px;
    inset-inline-end: 0;
  }

  @include respond-min($mobile-menu-breakpoint) {
    .layout--middle-east &:hover {
      .category__type {
        opacity: 1;
      }

      .category__text {
        color: $white;
        font-size: to-rem(36);
        line-height: to-rem(50);
        transform: translate(-50%, -50px);
      }

      &::before {
        opacity: 1;
      }
    }
  }
}

.product-dropdown-items--europe {
  &.product-dropdown-items--mobile-menu {
    padding: 0;
  }

  .category {
    @include respond-max($mobile-menu-breakpoint) {
      block-size: 3em;
      display: flex;
      align-items: center;

      &:not(:first-child) {
        border-block-start: 0 solid $neutral-300;
      }

      &::before {
        content: '';
        inline-size: 12px;
        block-size: 12px;
        border-radius: 100%;
        border: 2px solid $white;
        transform: translateY(-2px);
        position: relative;
        opacity: 1;
        margin-inline-end: 16px;
      }
    }

    &--tasty_shapes_seasoned,
    &--tasty_shapes_natural {
      &::before {
        background-color: $category-tasty-shapes-seasoned;
        border-color: $category-tasty-shapes-seasoned;
      }

      @include respond-min($mobile-menu-breakpoint) {
        background: radial-gradient(50% 50% at 50% 50%, rgb(227 84 37 / 50%) 0%, rgb(25 25 25 / 5%) 70%);

        &:hover {
          background: linear-gradient(180deg, #e35425 0%, $black 100%);
        }
      }
    }

    &--homestyle {
      &::before {
        background-color: $category-homestyle;
        border-color: $category-homestyle;
      }

      @include respond-min($mobile-menu-breakpoint) {
        background: radial-gradient(50% 50% at 50% 50%, rgb(250 193 51 / 50%) 0%, rgb(25 25 25 / 5%) 100%);

        &:hover {
          background: linear-gradient(180deg, #fac133 0%, rgb(25 25 25 / 10%) 100%);
        }
      }
    }

    &--specialities {
      &::before {
        background-color: $category-specialities;
        border-color: $category-specialities;
      }
      @include respond-min($mobile-menu-breakpoint) {
        background: radial-gradient(50% 50% at 50% 50%, rgb(230 231 229 / 50%) 0%, rgb(25 25 25 / 5%) 70%);

        &:hover {
          background: linear-gradient(180deg, #e6e7e5 0%, rgb(25 25 25 / 10%) 100%);
        }
      }
    }

    @include respond-min($mobile-menu-breakpoint) {
      padding: 35px 0;
      inline-size: 208px;
      block-size: 284px;

      &__image-wrapper {
        display: inline-block;

        :deep(img) {
          max-block-size: 160px;
          padding: 40px 40px 0;
          margin-inline-start: 10px;
        }
      }

      &:first-child {
        border-radius: $dropdown-border-radius 0 0 $dropdown-border-radius;
      }

      &:last-child {
        border-radius: 0 $dropdown-border-radius $dropdown-border-radius 0;
      }
    }
  }

  .category-mobile-menu {
    padding: 0;
    padding-inline-start: 54px;
  }
}

.category-mobile-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: to-rem(24);
  padding: 18px 21px;

  &__image {
    @include respond-min($mobile-menu-breakpoint) {
      display: none;
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 90px;
    inline-size: 90px;
    margin-inline-end: 21px;
  }
}

:deep(.category-mobile-menu__image) {
  max-inline-size: 100%;
}

.category--family {
  @include respond-min($mobile-menu-breakpoint) {
    padding-inline-start: 40px;
  }

  &::before {
    inset-inline-start: 0;
    border-radius: 45px 0 0 45px;
  }

  .category__type {
    color: $category-family;
  }

  &:hover {
    &::before {
      background-image: url('/backgrounds/categories/hover-family-moments.webp');
    }
  }
}

.category--special {
  .category__type {
    color: $category-special;
  }

  &:hover {
    &::before {
      background-image: url('/backgrounds/categories/hover-special-meals.webp');
    }

    &::after {
      @include respond-min($mobile-menu-breakpoint) {
        background-image: url('/backgrounds/dropdown-top-left.png');
        background-position: right top;
      }
    }
  }
}

.category--social {
  .category__type {
    color: $category-social;
  }

  &:hover {
    &::before {
      background-image: url('/backgrounds/categories/hover-social-moments.webp');
    }

    &::after {
      @include respond-min($mobile-menu-breakpoint) {
        background-image: url('/backgrounds/dropdown-top-right.png');
        background-position: left top;
      }
    }
  }
}

.category--special,
.category--social {
  &:hover::after {
    opacity: 1;
  }
}

.category--breakfast {
  padding-inline-end: 40px;

  &::before {
    inset-inline-end: 0;
    border-radius: 0 45px 45px 0;
  }

  .category__type {
    color: $category-breakfast;
  }

  &:hover {
    &::before {
      background-image: url('/backgrounds/categories/hover-breakfast-meals.webp');
    }
  }
}

:lang(ar) {
  .category--family {
    &::before {
      border-radius: 0 45px 45px 0;
    }
  }

  .category--special {
    &:hover::after {
      background-image: url('/backgrounds/dropdown-top-right.png');
      background-position: left top;
    }
  }

  .category--social {
    &:hover::after {
      background-image: url('/backgrounds/dropdown-top-left.png');
      background-position: right top;
    }
  }

  .category--breakfast {
    &::before {
      border-radius: 45px 0 0 45px;
    }
  }
}
</style>
