<template>
  <div class="country-switcher">
    <div class="heading heading--small">
      {{ $t('country_and_language_switcher.country_heading') }}
    </div>
    <div class="country-switcher__subheading">
      {{ $t('country_and_language_switcher.country_subheading') }}
    </div>
    <div class="country-switcher__items">
      <a
        v-for="region in regions"
        :key="region"
        :href="`/${i18n.locale.value}/${region}`"
        class="country-switcher__item"
        :class="{
          'country-switcher__item--active': isActiveRegion(region),
        }"
      >
        <img
          :src="`/country-flags/${region}.svg`"
          alt=""
          class="country-switcher__flag"
        >
        <span class="country-switcher__item-text">{{ $t(`countries_list.${region}`) }}</span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import MIDDLE_EAST_COUNTRIES from '@/constants/countries';
import useCurrentCountry from '@/composables/useCurrentCountry';

const i18n = useI18n();
const regions = MIDDLE_EAST_COUNTRIES;
const { currentCountry } = useCurrentCountry();

const isActiveRegion = (region: string): boolean => currentCountry === region;

</script>

<style lang="scss" scoped>
.country-switcher {
  color: $primary-color-700;
  padding: 32px 0 40px;
  display: flex;
  flex-direction: column;
  font-family: $primary-font;
  font-size: to-rem(16);

  &__subheading {
    text-transform: none;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-block-start: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    text-transform: none;
    border-radius: 20px;
    padding: 2px 8px;

    &:hover {
      background-color: $primary-color-200;
      text-decoration: none;
    }

    &--active {
      background-color: $primary-color-200;
    }
  }

  &__item-text {
    margin-inline-start: 8px;
    color: $primary-color-700;
  }

  &__flag {
    inline-size: 20px;
    block-size: 13px;
    box-shadow: 0 0 8px rgba($black, 0.2);
  }
}
</style>
