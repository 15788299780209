<template>
  <div
    ref="mobileMenu"
    class="mobile-menu"
    data-test="mobile-menu"
    :aria-hidden="isMobileMenuActive ? 'false' : 'true'"
    :class="{ 'mobile-menu--active': isMobileMenuActive }"
  >
    <div class="mobile-menu__inner">
      <nav class="mobile-menu__navbar" :class="{ 'mobile-menu__navbar--products-open': productsOpen }">
        <BaseDropdown
          v-if="i18n.locale.value !== ES"
          :horizontal="true"
          trigger-data-test="products-dropdown-trigger-mobile"
          class="mobile-menu__products"
          @open="toggleProducts"
        >
          <template #dropdown-trigger>
            {{ $t('general.products') }}
          </template>
          <template #dropdown-content>
            <ProductDropdownItems />
          </template>
        </BaseDropdown>
        <BaseLink
          v-for="navigationItem in navigationItems"
          :key="navigationItem.url"
          :url="navigationItem.url"
          class="main-navigation__item"
        >
          {{ navigationItem.title }}
        </BaseLink>
        <SecondaryNavigation />
      </nav>
    </div>

    <SocialMedia
      v-if="socialLinksSnippet"
      :content="socialLinksSnippet.content.social_links"
      class="mobile-menu__socials"
      :is-with-ellipse="true"
    />
  </div>
</template>

<script setup lang="ts">
import { useAsyncData, useRoute, useRuntimeConfig } from 'nuxt/app';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useI18n } from '#i18n';
import SocialMedia from '@/components/SocialMedia.vue';
import SecondaryNavigation from '@/components/base/SecondaryNavigation.vue';
import useCms from '@/composables/useCms';
import useMobileMenu from '@/composables/useMobileMenu';
import { ES } from '@/constants/locales';
import { useNavigationStore } from '@/stores/navigation';

const { isMobileMenuActive, closeMobileMenu } = useMobileMenu();
const store = useNavigationStore();
const route = useRoute();
const i18n = useI18n();
const config = useRuntimeConfig();

const navigationItems = computed(() => store.main(i18n.locale.value));

const mobileMenu = ref(null);
let header: HTMLElement | null;

const { getSnippet } = useCms(i18n, config.public.apiUrl, config.public.theme);
const { data: socialLinksSnippet } = await useAsyncData('social_snippet', () => getSnippet('social_links'));

onMounted(() => {
  header = document.querySelector('header');
});

const productsOpen = ref(false);
const toggleProducts = (open: boolean) => {
  productsOpen.value = open;
};

const handleScroll = () => {
  if (header === null) {
    return;
  }

  header.classList.add('header--background-color-light');
};

watch(
  () => isMobileMenuActive.value,
  () => {
    if (header === null) {
      return;
    }

    // resets scrollPosition when mobile menu opens
    if (isMobileMenuActive.value) {
      mobileMenu.value.scrollTop = 0;
      mobileMenu.value.addEventListener('scroll', handleScroll, { once: true });
    } else {
      header.classList.remove('header--background-color-light');
    }
  },
);

watch(
  () => route.fullPath,
  () => {
    if (isMobileMenuActive.value) {
      closeMobileMenu();
    }
  },
);
</script>

<style lang="scss" scoped>
.mobile-menu {
  opacity: 0;
  pointer-events: none;
  display: none;
  flex-direction: column;
  position: fixed;
  inset-block: 0;
  inset-inline: 0;
  visibility: hidden;
  inline-size: 100%;
  overflow-y: auto;

  &__navbar {
    overflow: visible;
    display: flex;
    flex-direction: column;
    padding: 110px 30px 0;
    gap: 40px;
    font-size: $mobile-menu-font-size;
    line-height: to-rem(50);
    text-transform: $main-navigation-text-transform;
    font-family: $title-font;

    :deep(.dropdown--open) {
      .dropdown__trigger {
        text-decoration: none;
      }

      .dropdown__items {
        margin-block: -40px 46px;
        margin-block-end: 46px;
        position: relative;

        &::before {
          background-position: 70px top;
        }
      }

      .dropdown__arrow {
        fill: $brand-yellow;
      }
    }
  }

  &__inner {
    padding-block-end: 150px;
  }

  // Custom mobile products dropdown styling
  .layout--europe,
  .layout--campaign & {
    &__products {
      :deep(.dropdown__items) {
        background-color: transparent;
        box-shadow: none;
        margin-block: -54px 48px;
      }
    }

    &__navbar {
      &--products-open {
        &::before {
          position: absolute;
          inline-size: 100%;
          block-size: 100%;
          inset-block-start: 48px + 2 * 16px;
          inset-inline-start: 0;
          content: '';
          opacity: 0.16;
          background: radial-gradient(189.49% 114.87% at 33.59% 5.19%, $white 0%, $black 100%);
        }
      }
    }
  }
}

.mobile-menu--active {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  z-index: map-get($z-index, 'mobile-menu');

  :deep(.secondary-navigation) {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: space-between;
  }

  :deep(.country-and-language-switcher) {
    display: flex;
    order: 1;
    text-transform: uppercase;
  }
}

:lang(ar) {
  .mobile-navigation {
    font-size: to-rem(28);
    line-height: 1.5;
  }
}
</style>
