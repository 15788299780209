<template>
  <header
    ref="headerElement"
    class="header"
    :class="{
      'header--sticky': y > 0,
      'header--mobile-menu-active': isMobileMenuActive,
    }"
  >
    <div ref="headerInnerElement" class="header__inner">
      <BaseLink url="/" class="header__logo" :aria-label="$t('general.homepage')">
        <Component :is="i18n.locale.value === AR ? LogoArabic : Logo" class="header__logo-svg" />
      </BaseLink>
      <MainNavigation />
      <SecondaryNavigation />
      <BaseHamburgerIcon
        data-test="hamburger-icon"
        class="header__hamburger"
        :active="isMobileMenuActive"
        :aria-label="$t('general.menu_toggle')"
        @click="
          toggleMobileMenu($event);
          addStickyToHeader();
        "
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { useScroll } from '@vueuse/core';
import { gsap } from 'gsap';
import { useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import {
  computed, ref, toRefs, watch, type PropType,
} from 'vue';
import Logo from '@/assets/icons/logo.svg?component';
import LogoArabic from '@/assets/icons/logo-arabic.svg?component';
import BaseHamburgerIcon from '@/components/base/BaseHamburgerIcon.vue';
import MainNavigation from '@/components/base/MainNavigation.vue';
import SecondaryNavigation from '@/components/base/SecondaryNavigation.vue';
import useUIHelper from '@/composables/useUIHelper';
import { AR } from '@/constants/locales';

defineProps({
  snippet: {
    type: Object as PropType<Object | null | void>,
    required: false,
    default: () => {},
  },
});

let lastDirection = '';
const menuAnimationDuration = 0.3;
const menuAnimationTextDelay = 0.05;
const { isMobileMenuBreakpoint } = useUIHelper();

const i18n = useI18n();
const config = useRuntimeConfig();
const { $bus } = useNuxtApp();

const { toggleMobileMenu, isMobileMenuActive } = useMobileMenu(config.public.theme);
const computedWindow = computed(() => {
  if (process.client) {
    return window;
  }

  return undefined;
});

const headerElement = ref<HTMLElement | null>(null);
const headerInnerElement = ref<HTMLElement | null>(null);

const hideMenuAnimation = (): void => {
  $bus.$emit('header-dropdown:close');

  gsap.to(headerInnerElement.value, {
    duration: menuAnimationDuration,
    y: -50,
  });

  gsap.to(headerElement.value, {
    duration: menuAnimationDuration,
    delay: isMobileMenuBreakpoint.value ? 0 : menuAnimationTextDelay,
    y: -110,
  });
};

const removeStickyFromHeader = (): void => {
  headerElement.value?.classList.remove('header--sticky');
};

const showMenuAnimation = (): void => {
  gsap.to(headerInnerElement.value, {
    duration: menuAnimationDuration,
    delay: isMobileMenuBreakpoint.value ? 0 : menuAnimationTextDelay,
    y: 0,
  });

  gsap.to(headerElement.value, {
    duration: menuAnimationDuration,
    y: 0,
  });
};

const { directions, arrivedState, y } = useScroll(computedWindow.value, {
  throttle: 200,
  onScroll: (): void => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (toBottom.value && y.value >= 60 && lastDirection !== 'bottom' && headerElement.value && !isMobileMenuActive.value) {
      lastDirection = 'bottom';
      hideMenuAnimation();
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (toTop.value && lastDirection !== 'top' && headerElement.value && !isMobileMenuActive.value) {
      lastDirection = 'top';
      showMenuAnimation();
    }
  },
});

const addStickyToHeader = (): void => {
  if (!headerElement.value?.classList.contains('header--sticky') && y.value >= 1) {
    headerElement.value?.classList.add('header--sticky');
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { top, bottom } = toRefs(arrivedState);
const { top: toTop, bottom: toBottom } = toRefs(directions);

watch(
  () => isMobileMenuActive.value,
  () => {
    if (isMobileMenuActive.value) {
      removeStickyFromHeader();
    }
  },
);
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  z-index: map-get($z-index, 'header');
  inset-block-start: 0;
  inline-size: 100vw;
  padding: 16px 0;
  font-family: $title-font;
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;

  &:lang(ar) {
    inline-size: 100%;
  }

  &::after {
    position: absolute;
    inset-block-end: 0;
    content: '';
    block-size: 4px;
    inline-size: 100%;
    background: linear-gradient(to right, $white, $brand-orange, $brand-yellow);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: map-get($z-index, 'negative');

    .layout--europe &,
    .layout--campaign & {
      display: none;
    }
  }

  @include respond-min($mobile-menu-breakpoint) {
    padding: 24px 0;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    block-size: 48px;

    @include respond-min($mobile-menu-breakpoint) {
      justify-content: flex-start;
      gap: 48px;
    }
  }

  &__logo {
    margin-inline-start: 16px;
    block-size: 100%;
    display: flex;
    align-items: center;

    @include respond-min($mobile-menu-breakpoint) {
      margin-inline-start: 160px;
    }
  }

  &__logo-svg {
    overflow: visible;
    margin-block-end: 10px;
    inline-size: 139px;
  }

  &__hamburger.hamburger-icon {
    margin-inline-end: 16px;

    &:lang(ar) {
      margin-inline-end: 0;
    }

    @include respond-min($mobile-menu-breakpoint) {
      display: none;
    }
  }
}

.header--sticky {
  background-color: $header-sticky-background;

  &::after {
    opacity: 1;
  }

  .layout--campaign & {
    background-color: rgba($black, 0.5);
  }
}

.header--mobile-menu-active {
  background-color: transparent;

  &::after {
    background: none;
  }
}

.header--background-color-light {
  background-color: $header-bg-light;
}

.layout--europe,
.layout--campaign {
  .header__logo {
    margin-inline-start: 16px;

    @include respond-min($mobile-menu-breakpoint) {
      margin-inline-start: 40px;
    }

    &-svg {
      margin-block-end: 0;

      @include respond-min($mobile-menu-breakpoint) {
        margin-block-end: 10px;
      }
    }
  }
}
</style>
